@import "shared";
.PannableContainer {
  @include vh(100);
  display: block;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.inner {
  display: block;
  position: absolute;
  width: 100%;
  transform: translate3d(0, 0, 0);
}

.contentContainer {
  display: block;
  width: 100%;
}
