@import "shared";
.Loader {
  @include vh(100);
  @include position-100(fixed);
  @include z-index(loader);
}

.image {
  transition: opacity 0.2s;
  transition-delay: 0.3s;
  width: 100%;
  max-width: 100%;
  display: block;
  pointer-events: none;
  opacity: 0;

  .loaderImageLoaded & {
    opacity: 1;
  }
}

.pannableContainer {
  z-index: 3;
}

.bar {
  position: fixed;
  width: 100%;
  height: 26px;
  border-top: 2px solid $black;
  background-color: $black;
  bottom: 0;
  left: 0;
  z-index: 4;
}

.loadableImageContainer {
  @include position-100(fixed);
  @include z-index(loadableImagesContainer);
  pointer-events: none;

  img,
  picture {
    position: absolute !important;
    width: px(1) !important;
    display: block;
    top: 0 !important;
    left: 0 !important;
    height: auto;
  }
}

.bar__inner {
  @include position-100(absolute);
  background-color: $white;
  transform-origin: center left;
  transform: scaleX(0);
}

.bar__innerText {
  font-size: 13.5px;
  color: $white;
  position: absolute;
  bottom: 3px;
  right: 10px;
}
