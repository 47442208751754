@import "shared";
.Wipe {
  @include z-index(wipe);
  @include position-100(fixed);
  overflow: hidden;

  &.wipeComplete {
    position: relative;
  }
}

.bg {
  @include position-100(absolute);
  clip-path: polygon(0% var(--left-y), 100% var(--right-y), 100% 100%, 0% 100%);
  min-height: 100%;

  --left-y: 100%;
  --right-y: 100%;

  .wipeComplete & {
    position: relative;
  }

  &.black {
    z-index: 1;
    background-color: $black;
  }

  &.inner {
    z-index: 2;
    background-color: $white;
  }
}

.children {
  position: relative;
  z-index: 3;
}

.blackBgWipe {
  @include position-100(absolute);
  z-index: 1;
  background-color: $black;
  opacity: 0;
}

.loadingText {
  @include font-monument;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  white-space: nowrap;
  text-transform: uppercase;
  font-style: italic;
  color: $pink;
  pointer-events: none;
  opacity: 0;
}

.bgTexture {
  @include position-100(fixed);
  @include vh(100);
  object-fit: cover;
  z-index: 2;
  pointer-events: none;
}
