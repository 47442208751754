@import "shared";
.Cursor {
  @include z-index(cursor);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  display: none;

  &.gallery {
    mix-blend-mode: difference;
  }

  @media (pointer: fine) {
    &.showCursor {
      display: block;
    }
  }
}

.cursorImage {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.1s;

  &.isActive {
    opacity: 1;
  }

  &.static {
    width: 105px;
    left: -15px;
    top: -32px;
  }

  &.hover {
    width: 117px;
    left: -15px;
    top: -43px;
  }

  &.hoverThumbnail {
    width: 117px;
    left: -15px;
    top: -43px;
  }

  &.gallery {
    width: px(57);
    left: -24px;
    top: -24px;
  }
}

.videoProgress,
.scrollIndicator {
  @include font-merchant;
  position: absolute;
  top: px(33);
  left: px(26);
  z-index: 245;
  opacity: 1;
  color: $grey;
  white-space: nowrap;
  pointer-events: none;
  display: none;

  @include bp(mobile) {
    display: none;
  }
}
