@import "shared";
.Layout {
  display: block;
}

.scrollContainer {
  position: relative;
  width: 100%;
  @include z-index(main);
  @include vh(100);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.scrollContainerInner {
  display: block;
}
