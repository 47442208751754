@import "shared";
// Global styling, normalize overwritten
@import 'fonts';
@import 'polyfills';
@import 'shared';

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $body-fonts;
  background-color: $white;
  scroll-behavior: initial;
  min-height: 100%;
  height: auto;
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  color: $black;
  background-color: $white;
  min-height: 100%;
  font-size: px(16);
  height: auto;

  @include position-100(fixed);
  overflow: hidden;

  // Variables
  --hover-touch-transition: 0.25s;
  --portfolio-ui-padding: #{$portfolio-ui-padding};

  @include bp(mobile) {
    --portfolio-ui-padding: #{$portfolio-ui-padding-mobile};
    --hover-touch-transition: 0.2s;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

main {
  position: relative;
  @include z-index(pages);
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// Hide cursor
body,
a,
button {
  cursor: none;

  &:hover {
    cursor: none;
  }
}

// Lenis

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}
